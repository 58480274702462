<template>
  <div class="user">
    <div class="search">
      <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input
        type="text"
        placeholder="请输入内容"
        v-model="searchValue"
      ></el-input>
      <div class="types">
        <el-select v-model="typeValue" placeholder="所属比赛">
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.matchName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-button @click="handleReast">
        <span>重置</span>
      </el-button>
      <el-button type="primary" @click="handleSearch">
        <span>查询</span>
      </el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="{ 'text-align': 'center', background: '#f2f2f2' }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column label="剧本名称" width="180">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.presTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="确权时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="文件类型">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ Type[scope.row.presType] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="确权类型">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.cpyType == 1 ? '文件确权' : '哈希确权' }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="创建人">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.presEntityName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属比赛">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.matchName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否入围">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.shortlist"
            active-value="YES"
            inactive-value="NO"
            @change="switchChange($event, scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleInfo(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: "剧本名称",
        },
        {
          value: 2,
          label: "创建人",
        },
      ],
      typeOptions: [],
      typeValue: "",
      value: 1,
      tableData: [],
      currentPage: 1,
      total: 0,
      Type: {
        104: "绘画",
        101: "文字",
        103: "音频",
        199: "视频",
        102: "摄影",
      },
      searchValue: "", // 搜索值
    };
  },
  methods: {
    // 重置
    handleReast() {
      this.value = 1;
      this.typeValue = "";
      this.searchValue = "";
      this.getList();
    },
    // 查询
    handleSearch() {
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    // 确权列表
    getList() {
      let params = {
        current: this.currentPage,
        pageSize: 10,
        contestId: this.typeValue,
      };
      if (this.value == 1) {
        params.presTitle = this.searchValue;
      } else {
        params.presEntityName = this.searchValue;
      }
      this.$axiosGet("presEntityManage/queryPage", params).then((res) => {
        console.log(res);
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 查看文档
    handleInfo(row) {
      window.open(
        "https://view.officeapps.live.com/op/view.aspx?src=" + row.documentUrl
      );
    },
    // 获取所有比赛列表
    getconst() {
      this.$axiosGet("matchManager/queryContestQueryParam", {}).then((res) => {
        console.log(res);
        this.typeOptions = res.data;
      });
    },
    // 是否入围
    switchChange(e, row) {
      this.$axiosPost("presEntityManage/markShortlist", {
        presId: row.id,
        shortlist: e,
      }).then(() => {
        this.getList();
      });
    },
  },
  created() {
    this.getList();
    this.getconst();
  },
};
</script>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  margin-bottom: 38px;

  .el-input {
    width: 200px;
    margin: 0 10px;
  }

  .types {
    margin-right: 10px;
  }
}

.pagination {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.el-table {
  .fileName {
    // display: flex;
    // align-items: center;

    img {
      width: 60px;
      height: 60px;
      background: #ccc;
    }
  }
}

.el-button {
  padding: 10px 20px;
}
</style>
